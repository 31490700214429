import React from 'react';
import { CurrencyDisplayStyle, FormattedCurrency } from '../components/DataDisplay/FormattedCurrency';
import { DateTimeDisplayStyle, FormattedDateTime } from '../components/DataDisplay/FormattedDateTime';
import glamorous from 'glamorous';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { IntlProvider, FormattedDate } from 'react-intl';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Button from '@material-ui/core/Button';
import { FormattedPercentage } from '../components/DataDisplay/FormattedPercentage';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { TooltipHost } from 'office-ui-fabric-react';
import { Checkbox, Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, Editor, EditorState } from 'draft-js';
import { Rating } from '@material-ui/lab';
import moment from 'moment';
import { CurrenciesData } from '../firmSettingConfiguration/common/Currencies';

// tslint:disable-next-line:no-any
const CheckboxContainer = glamorous.div<{ theme?: any }>((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`& .${`ms-IconExample`}`]: {
        fontSize: props.theme.FontSize.PX.size5,
    }    
}));

// tslint:disable-next-line:no-any
const ButtonContainer = glamorous.div<{ theme?: any }>((props) => ({
    display: 'inline-block',
    width: '100%',
    height: '100%' 
}));

const DateContainer = glamorous.div<{ theme?: object }>((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const NumberContainer = glamorous.div<{ theme?: object }>((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const CurrencyValue = glamorous(FormattedCurrency)<{ theme?: object }>((props) => ({
     textAlign: 'right'
}));

const CurrencyContainer = glamorous.div<{ theme?: object }>((props) => ({
    display: 'inline-block',
    width: '100%',
    textAlign: 'right'
}));

const PercentageContainer = glamorous.div<{ theme?: object }>((props) => ({
    display: 'inline-block',
    width: '100%',
    textAlign: 'center'
}));

const ImageContainer = glamorous.div<{ theme?: object }>((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',    
    // width: '100%' ,
    height: '100%'
}));

const ImageDiv = glamorous.div<{ theme?: object }>((props) => ({
    width: '100%',
    minHeight: '30px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
}));

const PercentageValue = glamorous(FormattedPercentage)<{ theme?: object }>((props) => ({
    textAlign: 'right'
}));

const LongTextContainer = glamorous.div<{ theme?: object }>((props) => ({
    display: 'inline-block',
    width: '100%',
    '& .ms-TooltipHost': {
        display: 'flex',
        width: '100%',
    }
}));

const EditorViewContainer = glamorous.div<{ theme?: object }>((props) => ({
    display: 'inline-block',
    width: '100%',
    '& .ms-TooltipHost': {
        display: 'flex',
        width: '100%',
    },
    '& .public-DraftStyleDefault-ltr': {
        margin: 0
    }
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',            
            position: 'fixed',
        },
        chip: {
            margin: theme.spacing() / 4,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        dateContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    })
);

export class CurrencyRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
    }

    render() {
        // or access props using 'this'
        return (
            <CurrencyContainer>
                {
                    this.params.value != 0
                    ?
                        (       
                            // tslint:disable-next-line: no-any               
                            <Query<any>
                                query={localCurrency}
                                fetchPolicy="cache-first"
                            >
                                {({loading, error, data }) => {
                                    const lCurrency = ( loading || error ) ? null : data.options.localCurrency;
                                    let currency = lCurrency ? lCurrency.currency : null;

                                    const lTenant = ( loading || error ) ? null : data.tenant;
                                    const sourceSystem = lTenant ? lTenant.sourceSystem : null;

                                    const lAppUser = ( loading || error ) ? null : data.appUser;
                                    const isInternal = lAppUser ? lAppUser.isInternal : null;

                                    if (this.params.data) {
                                        let foreignCurrency = this.params.data.foreignCurrency;

                                        // If internal user - then the local currency is the priority. 
                                        // But if it is a external user, and if there is a foreign currency, then display that.
                                        if (sourceSystem && sourceSystem.toLocaleLowerCase() === 'inprotech' && foreignCurrency && !isInternal) {
                                            const foreignCurrencySybmol = CurrenciesData.find(currencyItem => currencyItem.currency === foreignCurrency);
                                            if (foreignCurrencySybmol) {
                                                currency = foreignCurrencySybmol.currency;
                                            }
                                        }
                                    }

                                    return (
                                        <CurrencyValue
                                            value={this.params.value} 
                                            displayStyle={CurrencyDisplayStyle.Decimal}
                                            format={currency} 
                                        />
                                    );
                                }}
                            </Query>
                        )
                     :
                    <div/>
                }
                
            </CurrencyContainer>                
        );
    }
}

export class CurrencyRendererWithoutDecimal extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
    }

    render() {
        // or access props using 'this'
        return (
            <CurrencyContainer>
                {
                    this.params.value > 0 
                    ?
                        (       
                            // tslint:disable-next-line: no-any               
                            <Query<any>
                                query={localCurrency}
                            >
                                {({loading, error, data }) => {
                                    const lCurrency = ( loading || error ) ? null : data.options.localCurrency;
                                    const currency = lCurrency ? lCurrency.currency : null;
                                    return (
                                        <CurrencyValue
                                            value={this.params.value} 
                                            displayStyle={CurrencyDisplayStyle.Integer}
                                            format={currency} 
                                        />
                                    );
                                }}
                            </Query>
                        )
                     :
                    <div/>
                }
                
            </CurrencyContainer>                
        );
    }
}

export class NumberRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
    }

    render() {
        // or access props using 'this'
        return (
            <NumberContainer>
                {
                    this.params.value > 0 
                    ?
                        (       
                            <span>
                                {this.params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </span>
                        )
                     :
                    <div/>
                }
                
            </NumberContainer>                
        );
    }
}

export class PercentageRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
    }

    render() {
        // or access props using 'this'
        return (
            <PercentageContainer>
                {
                    this.params.value > 0 
                    ?                        
                        <PercentageValue
                            value={this.params.value}
                        />
                     :
                    <div/>
                }                
            </PercentageContainer>                
        );
    }
}

export class DateRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
    }

    render() {
        if (this.params.value) {

            // let shortDateFormat = { year: 'numeric', day: 'numeric', month: 'short' };
            // let shortTimeFormat = { hour: 'numeric', minute: 'numeric', hour12: true };
            // let format = {...shortDateFormat};
            var convertedLocalTime = new Date(Date.parse(this.params.value.toString()));
            // var hourOffset = convertedLocalTime.getTimezoneOffset() / 60;
            // convertedLocalTime.setHours( convertedLocalTime.getHours() - hourOffset );            
            
            return (
                <DateContainer>
                    <div>
                        <IntlProvider
                            locale="en-AU"
                            formats={{}}
                            defaultLocale="en"
                        >
                            <span>
                                <FormattedDate
                                    // tslint:disable-next-line:max-line-length
                                    value={convertedLocalTime}
                                    day={'numeric'}
                                    month={'short'}
                                    year={'numeric'}
                                    // {...format}
                                />
                            </span>
                        </IntlProvider>
                    </div>
                </DateContainer>
            );
        }
        return <div/>;
    }
}

export class TimeRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
    }

    render() {
        // or access props using 'this'
        if (this.params.value) {
            return (
                <DateContainer>
                    <FormattedDateTime
                        value={this.params.value} 
                        displayStyle={DateTimeDisplayStyle.ShortTime} 
                        DisplayInUTC={false}                        
                    />
                </DateContainer>
            );
        }
        return <div/>;
    }
}

export class DurationRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
    }

    render() {
        // or access props using 'this'
        if (this.params.value && this.params.value.value) {
            return (
                <DateContainer>
                    {this.params.value.value}
                    {/* {this.params.value.slice(0, -3)} */}
                </DateContainer>
            );
        } else if (this.params.value && this.params.value.value === undefined) {
            return (
                <DateContainer>
                    {this.params.value}
                    {/* {this.params.value.slice(0, -3)} */}
                </DateContainer>
            );
        }
        return <div/>;
    }
}

export class DateTimeRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
    }

    render() {
        // or access props using 'this'
        if (this.params.value) {
            return (
                <DateContainer>
                    <FormattedDateTime
                        value={this.params.value} 
                        displayStyle={DateTimeDisplayStyle.ShortDateTime} 
                        DisplayInUTC={false}                        
                    />
                </DateContainer>
            );
        }
        return <div/>;
    }
}

export class LocalDateTimeRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
    }

    render() {
        // or access props using 'this'
        if (this.params.value) {

            // let shortDateFormat = { year: 'numeric', day: 'numeric', month: 'short' };
            // let shortTimeFormat = { hour: 'numeric', minute: 'numeric', hour12: true };
            // let format = {...shortDateFormat, ...shortTimeFormat};
            var convertedLocalTime = new Date(Date.parse(this.params.value.toString()));
            var hourOffset = convertedLocalTime.getTimezoneOffset() / 60;
            convertedLocalTime.setHours( convertedLocalTime.getHours() - hourOffset );            
            
            return (
                <DateContainer>
                    <div>
                        <IntlProvider
                            locale="en-AU"
                            formats={{}}
                            defaultLocale="en"
                        >
                            <span>
                                <FormattedDate
                                    // tslint:disable-next-line:max-line-length
                                    value={convertedLocalTime}
                                    day={'numeric'}
                                    month={'short'}
                                    year={'numeric'}
                                    hour={'numeric'}
                                    minute={'numeric'}
                                    hour12={true}

                                    // {...format}
                                />
                            </span>
                        </IntlProvider>
                    </div>
                </DateContainer>
            );
        }
        return <div/>;
    }
}

export class BoolRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
        this.getGroupColumnHeader = this.getGroupColumnHeader.bind(this);
    }

    render() {
        if (this.params.value !== undefined) {
            return (
                <CheckboxContainer>
                    {this.getGroupColumnHeader()}
                    {this.params && (this.params.value === true || this.params.value === 'true') ? 
                        <Icon
                            iconName={'SkypeCheck'} 
                            className="ms-IconExample" 
                        />
                        :
                        ''
                    }
                   
                </CheckboxContainer>
            );
        }
        return <div />;
    }

// tslint:disable-next-line: no-any
    private getGroupColumnHeader() {
        if (this.params && this.params.value === 'true') {
            let column = this.params.columnApi.getColumn(this.params.node.field);

            if (column !== undefined || column !== null) {
               return column.getColDef().headerName + ' '; 
            } else {
                return <div/>;
            }
        } else {
            return <div/>;
        }
    }
}

export class ButtonRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;   
        // from here you can access any of the props!
        // tslint:disable-next-line: no-console
        console.log(this.params.value);
    }

    render() {
        return (
            <ButtonContainer>
                <Button variant="contained" size="small" color="secondary">
                    Secondary
                </Button>
            </ButtonContainer>
        );
    }
}

export class ImageRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;   
        // from here you can access any of the props!
    }

    render() {
        if (this.params.value !== undefined) {
            return (
                <ImageContainer>
                    <ImageDiv 
                        className="firm-image"
                        style={{ backgroundImage: `url(${this.params.value})` }}
                    />
                    {/* <img 
                        src={this.params.value} 
                        style={{ 
                                maxHeight: '40px', 
                                height: 'auto', 
                                width: '100%'
                               }} 
                    />      */}
                </ImageContainer>
            );
        }
        return <div />;
    }
}

export class LongTextRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;   
        // from here you can access any of the props!
    }

    render() {
        if (this.params.value !== undefined) {
            return (
                <LongTextContainer className="long-text-container">
                    <TooltipHost
                        content={this.params.value}
                        // This id is used on the tooltip itself, not the host
                        // (so an element with this id only exists when the tooltip is shown)
                        id={this.params.value}
                    >
                        {this.params.value}
                    </TooltipHost>
                </LongTextContainer>
            );
        }
        return <div />;
    }
}

export class MultiLineStringRenderrer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;   
        // from here you can access any of the props!
    }

    render() {
        if (this.params.value !== undefined || this.params.value !== null) {

            const tempValue = this.params.value || '';

            return (
                <LongTextContainer className="long-text-container">
                    <TooltipHost
                        content={(
                            <div 
                                dangerouslySetInnerHTML={{
                                    __html: tempValue
                                }}  
                            />
                        )}
                        // This id is used on the tooltip itself, not the host
                        // (so an element with this id only exists when the tooltip is shown)
                        id={tempValue ?? undefined}
                    >
                        {tempValue.replace(/<br\/>/g, ' ')}
                    </TooltipHost>
                </LongTextContainer>
            );
        }
        return <div />;
    }
}

export class CheckboxCellRenderer extends React.Component {
    private params: ICellRendererParams;

    // tslint:disable-next-line: no-any
    constructor(props: any) {
        super(props);
        this.params = props;   
        this.onCheck = this.onCheck.bind(this);
    }

    onCheck(event: React.ChangeEvent<HTMLInputElement>) {
        let checked = event.target.checked;
        let colId = this.params.column.getColId();
        this.params.node.setDataValue(colId, checked);
    }

    render() {
        if (this.params.value !== undefined) {
            return (
                // <input 
                //     type="checkbox" 
                //     onClick={this.checkedHandler}
                //     checked={this.props.value}
                // />

                <Checkbox 
                    name="isCloseMatter" 
                    onChange={(event) => this.onCheck(event)}
                    checked={this.params.value}
                />
            );
        } else {
            return (
                <Checkbox 
                    name="isCloseMatter" 
                    onChange={(event) => this.onCheck(event)}
                    checked={false}
                />
            );            
        }
        // return <div />;
    }
}

export class EditorViewRenderer extends React.Component {
    private params: ICellRendererParams;
    // did you know that React passes props to your component constructor??
    constructor(props: ICellRendererParams) {
        super(props);
        this.params = props;       
        // from here you can access any of the props!
    }

    render() {
        if (this.params.value) {

            let editorState = EditorState.createEmpty();
            const contentBlock = htmlToDraft(this.params.value);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                editorState = EditorState.createWithContent(contentState);
            }      
            
            // const fieldId = Math.random().toString();
            
            return (
                <EditorViewContainer className="long-text-container">
                    {/* <TooltipHost
                        content={editorState}
                        // This id is used on the tooltip itself, not the host
                        // (so an element with this id only exists when the tooltip is shown)
                        id={fieldId}
                    > */}
                    <Editor 
                        editorState={editorState}
                        readOnly={true}
                        onChange={() => null}
                    />
                        {/* {editorState} */}
                    {/* </TooltipHost> */}
                </EditorViewContainer>
            );
        }
        return <div/>;
    }
}

export const ChipCellRenderer: React.FC<ICellRendererParams> = ({value}) => {
    const classes = useStyles();

    const getChips = (): React.ReactNode => {
        if (value) {
            let chipsArray = [];
            if (!Array.isArray(value)) {
                // tslint:disable-next-line: no-any
                JSON.parse(value).forEach( (item: any) => {
                    chipsArray.push(item);
                });
            } else {
                chipsArray = value;
            }

            const chips: React.ReactNode = (
                <div className={classes.chips}>
                    {
                        // tslint:disable-next-line: no-any
                        chipsArray.map((chipData: any) => {    
                            return (
                                // tslint:disable-next-line: no-unused-expression
                                <Chip 
                                    className={classes.chip} 
                                    key={chipData.value} 
                                    label={chipData.label}
                                />
                            );
                        })
                    }
                </div>
            );
            return chips;
        } else {
            return null;
        }  
    };

    return (
        <React.Fragment>
            {getChips()}
        </React.Fragment>
    );  
};

export const RatingCellRenderer: React.FC<ICellRendererParams> = ({value}) => {
    // const classes = useStyles();

    const getRating = (): React.ReactNode => {

        if (!value) {
            return null;
        }

        let tempValue = 0;

        if (typeof value === 'string') {
            // tslint:disable-next-line: radix
            if (isNaN(parseInt(value))) {
                tempValue = value.length;
            } else {
                // tslint:disable-next-line: radix
                tempValue = parseInt(value);
            }
        } else {
            tempValue = value;
        }
        
        const rating: React.ReactNode = (
            <div>
                <Rating
                    readOnly={true}
                    value={tempValue}
                />                    
            </div>
        );
        return rating;
    };

    return (
        <React.Fragment>
            {getRating()}
        </React.Fragment>
    );  
};

export const DateMonthYearRenderer: React.FC<ICellRendererParams> = ({value}) => {
    const classes = useStyles();

    if (value) {

        const utcDate = moment.utc(value).toDate();

        const localDate = moment(utcDate).local().format('YYYY-MM MMM');

        return (
            <div className={classes.dateContainer}>
                {localDate}
            </div>
        );
    }
    
    return null;
};

const localCurrency = gql`query localCurrency {
    options {
        localCurrency {
            currency
        }
    }
    tenant {
        sourceSystem
    }
    appUser {
        isInternal
    }
}`;