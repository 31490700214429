import gql from 'graphql-tag';
import { client } from '..';
import { FetchPolicy } from 'apollo-client';
import { Options, ColumnConfigList, ColumnConfig } from '../viewConfig/SummaryViewConfigurations';
import { MatterData } from './models/Matter';
import { TenantData } from '../hooks/useTenant';

export interface MatterSummaryQueryParams {
    offset: number; 
    first: number;
    filter?: string | null; 
    includeCompleted: boolean;
    sortColumn?: string; 
    sortDirection?: string;
}

// New Matter Summary Query params
export interface MatterSummaryQueryXParams {
    offset: number; 
    first: number;
    filter?: string | null; 
    isIncludeCompleted: boolean;
    sortColumn?: string; 
    sortDirection?: string;
    clientName?: string;
    matterStatus?: string;
    personActingId?: number;
    personResponsibleId?: number;
    wipBalanceGreaterThanAmount?: number;
    debtorBalanceGreaterThanAmount?: number;
    isIncludeMattersWithTrustBalance?: boolean;
    nameId?: number;
    isReferredMatters?: boolean;
    isLimitedMatterSearch?: Boolean;
    searchCriteriasJson?: string;
    feeEarnerId?: string;
    isDropdownSearch?: boolean;
}

export interface MatterSummaryListData {
    loading?: boolean;
    networkStatus?: number;
    matterSummaryList: MatterSummaryList;
}

export interface MatterSummaryListXData {
    loading?: boolean;
    networkStatus?: number;
    matterSummaryListX: MatterSummaryListX;
}

export interface AppUser {
    isAdministrator: boolean;
}

export interface MatterOptionsData {
    loading?: boolean;
    networkStatus?: number;
    appUser: AppUser;
    options: Options;
    columnConfigList: ColumnConfigList;
}

export interface RoleData {
    loading?: boolean;
    networkStatus?: number;
    roleList: {
        role: Array<{
            roleIdString: string,
            rolename: string
        }>
    };
}

// Generated by https://quicktype.io
export interface MatterSummaryList {
    recordCount: number;
    matters:     MatterSummary[];
    matterSummaryActualCount: MatterSummaryActualCount;
}

export interface MatterSummaryListX {
    recordCount: number;
    rowCountWithoutFilters: number;
    matters:     MatterSummaryX[];    
}

export interface MatterSummary {
    id:                        number;
    imageLocation:             string;
    fileNumber:                string;
    matterCategory:            string;
    matterType:                string;
    matterTypeCategory:        null | string;
    stage:                     null;
    title:                     string;
    description:               string;
    client:                    string;
    otherSide:                 null | string;
    clientReference:           null | string;
    status:                    MatterStatus;
    latestComment:             null | string;
    last3Comments:             null | string;
    completionOrSettlmentDate: null | string;
    instructionDate:           string;
    nextAction:                null;
    nextActionDate:            null;
    nextDueDescription:        null;
    billed:                    boolean;
    unbilled:                  boolean;
    debtors:                   string;
    estimatedCosts:            number;
    fIRBStatus:                null;
    lastUpdate:                string;
    inProgress:                boolean;
    syncSystemID:              null;
    state:                     null;
    family:                    null;
    property:                  null;
    propertyLabel:             null;
    owner:                     null | string;
    nextRenewalDate:           null;
    country:                   null;
}

export interface MatterSummaryX {
    id: number;
    matterGuidId: string;
    fileNumber: string;
    status: string;
    title: string;
    longTitle: string;
    countryID: number;
    country: string;
    state: string;
    categoryID: number;
    category: string;
    typeID: number;
    type: string;
    typeClassification: string;
    typeState: string;
    subTypeID: number;
    subType: string;
    stage: string;
    fIRB: Boolean;
    family: string;
    property: string;
    propertyLabel: string;
    client: string;
    clientReference: string;
    otherSide: string;
    owner: string;
    billed: number;
    unbilled: number;
    unbilledFees: number;
    unbilledDisbursements: number;
    debtors: number;
    estimatedCosts: number;
    percentageEstimate: number;
    exposure: number;
    trustBalance: number;
    completionOrSettlmentDate: Date;
    instructionDate: Date;
    nextAction: string;
    nextActionDate: Date;
    nextDueDescription: string;
    fileLocation: string;
    imageLocation: string;
    latestComment: string;
    inProgress: Boolean;
    publishedDate: Date;
    lastUpdate: Date;
    nextRenewalDate: Date;
    isVIP: Boolean;
    syncSystemID: number;
    responsibleID: number;
    responsible: string;
    responsibleEmail: string;
    responsiblePhone: string;
    actingID: number;
    acting: string;
    actingEmail: string;
    actingPhone: string;
    assistingID: number;
    assisting: string;
    assistingEmail: string;
    assistingPhone: string;
    price: number;
    deposit: number;
    exchangeDate: Date;
    coolingOffDate: Date;
    depositDueDate: Date;
    depositPaidDate: Date;
    earliestPriorityDate: Date;
    applicationNumber: number;
    publicationDate: Date;
    publicationNumber: string;
    grantNumber: string;
    class: string;
    goodsAndServices: string;
    costAgreementReceivedDate: Date;
    costAgreementRequired: Boolean;
    referrer: string;
    trustAccountBalance: number;
    priorityCountry: string;
    reviewDate: Date;
    injuryDate: Date;
    proceedingsIssuedDate: Date;
    sunsetOrLimitationDate: Date;
    hearingDate: Date;
    trustee: string;
    trusteeEmail: string;
    isAwaitingInstructions: Boolean;
    clientType: string;
    foreignCurrency: string;
    lastTrustTransactionDate: Date;
    lastTrustTransactionType: string;
    fundsRecovered: number;
}

export interface MatterSummaryActualCount {
    actualRowCountForSearch:   number;
}

export enum MatterStatus {
    InProgress = 'In Progress',
    Unexchanged = 'Unexchanged',
}

export function retrieveMatterSummaryData(query: MatterSummaryQueryParams, 
                                          refreshData: boolean,
                                          onSuccess: (data: MatterSummaryListData) => void,
                                          // tslint:disable-next-line:no-any
                                          onError: (reason: any) => void)
                                          : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: MatterSummaryData,
        variables: {
            offset: query.offset,
            first: query.first,
            filter: query.filter,
            includeCompleted: query.includeCompleted,
            sortColumn: query.sortColumn,
            sortDirection: query.sortDirection
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
            onSuccess(results.data);
            })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); }
    );
}

export function retrieveMatterSummaryXData(query: MatterSummaryQueryXParams, 
                                           refreshData: boolean,
                                           // tslint:disable-next-line: no-any
                                           onSuccess: (data: any) => void,
                                            // tslint:disable-next-line:no-any
                                           onError: (reason: any) => void)
                                            : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: MatterSummaryXData,
        variables: {
            offset: query.offset,
            first: query.first,
            filter: query.filter,
            isIncludeCompleted: query.isIncludeCompleted,
            sortColumn: query.sortColumn,
            sortDirection: query.sortDirection,
            clientName: query.clientName,
            matterStatus: query.matterStatus,
            personActingId: query.personActingId,
            personResponsibleId: query.personResponsibleId,
            wipBalanceGreaterThanAmount: query.wipBalanceGreaterThanAmount,
            debtorBalanceGreaterThanAmount: query.debtorBalanceGreaterThanAmount,
            isIncludeMattersWithTrustBalance: query.isIncludeMattersWithTrustBalance,
            nameId: query.nameId,
            isReferredMatters: query.isReferredMatters,
            searchCriteriasJson: query.searchCriteriasJson ? query.searchCriteriasJson : null
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { 
        onError(reason); 
    });
}

const MatterSummaryData = gql`
    query MatterSummaryData(
        $offset: Int, $first: Int,
        $filter: String, $includeCompleted: Boolean,
        $sortColumn: String, $sortDirection: String
    ) {
        matterSummaryList(
            offset: $offset, 
            first: $first, 
            filter: $filter, 
            includeCompleted: $includeCompleted, 
            sortColumn: $sortColumn, 
            sortDirection: $sortDirection
        ) {
            recordCount,
            matterSummaryActualCount{
                actualRowCountForSearch
            },
            matters {
                id
                imageLocation
                fileNumber
                matterCategory
                matterType
                matterTypeCategory
                stage
                title
                description
                client
                otherSide
                clientReference
                status
                latestComment
                last3Comments
                completionOrSettlmentDate
                instructionDate
                nextAction
                nextActionDate
                nextDueDescription
                billed
                unbilled
                debtors
                estimatedCosts
                fIRBStatus
                lastUpdate
                inProgress
                syncSystemID
                state
                family
                property
                propertyLabel
                owner
                nextRenewalDate
                country
            }    
        }
    }
`;

const MatterSummaryXData = gql`
    query MatterSummaryXData(
        $offset: Int, $first: Int,
        $filter: String, $isIncludeCompleted: Boolean, 
        $sortColumn: String, $sortDirection: String,
        $clientName: String, $matterStatus: String,
        $personActingId: Int, $personResponsibleId: Int,
        $wipBalanceGreaterThanAmount: Decimal, $debtorBalanceGreaterThanAmount: Decimal,
        $isIncludeMattersWithTrustBalance: Boolean,
        $nameId: Int,
        $isReferredMatters: Boolean,
        $searchCriteriasJson: String
    ) {
        matterSummaryListX(
            offset: $offset, first: $first, filter: $filter, 
            isIncludeCompleted: $isIncludeCompleted, 
            sortColumn: $sortColumn, sortDirection: $sortDirection,
            clientName: $clientName, matterStatus: $matterStatus,
            personActingId: $personActingId, personResponsibleId: $personResponsibleId,
            wipBalanceGreaterThanAmount: $wipBalanceGreaterThanAmount, 
            debtorBalanceGreaterThanAmount: $debtorBalanceGreaterThanAmount,
            isIncludeMattersWithTrustBalance: $isIncludeMattersWithTrustBalance,
            nameId: $nameId,
            isReferredMatters: $isReferredMatters,
            searchCriteriasJson: $searchCriteriasJson
        ) {
            recordCount,
            rowCountWithoutFilters,
            matters {
                id
                matterGuidId
                fileNumber 
                imageLocation
                status 
                title 
                longTitle 
                countryID 
                country 
                state 
                categoryID 
                category 
                typeID 
                type 
                typeClassification
                typeState 
                subTypeID 
                subType 
                stage 
                fIRB 
                family 
                property 
                propertyLabel 
                client         
                clientReference 
                otherSide 
                owner 
                billed
                billedFees
                unbilled
                unbilledFees
                unbilledDisbursements 
                debtors 
                estimatedCosts
                percentageEstimate
                exposure
                trustBalance 
                completionOrSettlmentDate 
                instructionDate 
                nextAction 
                nextActionDate 
                nextDueDescription 
                fileLocation            
                latestComment 
                inProgress 
                publishedDate 
                lastUpdate 
                nextRenewalDate 
                isVIP 
                syncSystemID 
                responsibleID 
                responsible 
                responsibleEmail 
                responsiblePhone 
                actingID 
                acting 
                actingEmail 
                actingPhone 
                assistingID 
                assisting 
                assistingEmail 
                assistingPhone 
                price 
                deposit 
                exchangeDate 
                coolingOffDate 
                depositDueDate 
                depositPaidDate 
                earliestPriorityDate 
                applicationNumber 
                publicationDate 
                publicationNumber 
                grantNumber 
                class 
                goodsAndServices
                costAgreementReceivedDate
                costAgreementRequired
                referrer
                trustAccountBalance
                priorityCountry
                reviewDate
                injuryDate
                proceedingsIssuedDate
                sunsetOrLimitationDate
                hearingDate
                trustee
                trusteeEmail
                isAwaitingInstructions
                clientType
                foreignCurrency
                lastTrustTransactionDate
                lastTrustTransactionType
                fundsRecovered
                forecastFeesBilledThisMonth
                forecastFeesBilledNextThreeMonths
                forecastFeesBilledNextSixMonths
                forecastFeesCollectedThisMonth
                forecastFeesCollectedNextThreeMonths
                forecastFeesCollectedNextSixMonths
                forecastHoursThisMonth
                forecastHoursNextThreeMonths
                forecastHoursNextSixMonths
                expectedFutureFees
                archivedDate
                injuriesDescription
            }    
        }
    }
`;

export const MatterSummaryOptions = gql`query MatterSummaryOptions{
    appUser {
        isAdministrator
    }
    options {       
        matterViewOptions {
            canAdd
            canDelete
            canUpdate
        }
    }    
}`;

export function fetchColumnConfigData(refreshData: boolean,
                                      configType: number, 
                                      onSuccess: (data?: ColumnConfig) => void,
                                      // tslint:disable-next-line:no-any
                                      onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
                        query: ColumnConfigData,
                        variables: {
                                    configType: configType
                                },
                        fetchPolicy: fetchPolicy
                    })
            // tslint:disable-next-line:no-any
            .then((results: { data: any; }) => {
                    onSuccess(results.data);
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any ) => { onError(reason); }
        );
}

export const ColumnConfigData = gql`query ColumnConfigListData($configType: Int) {
    columnConfigList (configType: $configType){
        summaryViewConfig{
          id,
          configId,
          name,
          isSecurity,
          isPrivate,
          isDefault,
          isRoleDefault,
          isTenantDefault
          configType,
          layoutConfiguration
        }        
      }
}`;

export function fetchMatterSummaryNewEnabled(refreshData: boolean,
                                             onSuccess: (data?: MatterSummaryNewEnabledOption) => void,
                                             // tslint:disable-next-line:no-any
                                             onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
            query: MatterSummaryNewEnabledData,
            variables: {},
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
            .catch((reason: any ) => { onError(reason); }
        );
}

export interface MatterSummaryNewEnabledOption {
    options: MatterSummaryNewEnabled;
}

export interface MatterSummaryNewEnabled {
    matterSummaryNewEnabled: MatterSummaryBooleanValue;   
}
export interface MatterSummaryBooleanValue {
    boolValue: boolean;
}

export const MatterSummaryNewEnabledData = gql`query MatterSummaryNewEnabled{
    options{
        matterSummaryNewEnabled{
            boolValue
        }
    }
}`;

export const localCurrency = gql`query localCurrency {
    options {localCurrency {currency}}
}`;

export interface MatterSummaryActualCount {
    actualRowCountForSearch:   number;
}

export function fetchMatterDetailsData(refreshData: boolean,
                                       matterId: number,
                                       onSuccess: (data?: MatterData) => void,
                                       // tslint:disable-next-line:no-any
                                       onError: (reason: any) => void)
: void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: matterDetailsData,
        variables: {matterId: matterId},
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); });
}

export const matterDetailsData = gql`
query matterDetails ($matterId: Int!){
    matter(id: $matterId){
        id,
        matterGuid,
        fileNumber,
        acting,
        actingEmail,
        responsible,
        responsibleEmail,
        client,
        description,
        title,
        completionDate,
        actions{
            canBillMatter,
            canWriteOffUnbilledFees,
            canTransferTrustToPayInvoice,
            canWriteOffDebtors,
            canCloseMatter,
            canArchiveMatter,

            hasBillMatterTask,
            hasWriteOffUnbilledFeesTask,
            hasTransferTrustToPayInvoiceTask,
            hasWriteOffDebtorsTask,
            hasCloseMatterTask,
            hasArchiveMatterTask,
            hasMatterTask,

            pendingWriteOffFeesAmountInBillMatterTask,
            pendingWriteOffFeesAmountInWriteOffWipTask,
            pendingWriteOffDisbAmountInBillMatterTask,
            pendingWriteOffDisbAmountInWriteOffWipTask
        }
        financials{
            unbilledTimeAndFees,
            unbilledDisbursements,
            debtors: balanceDue,
            trustBalance: trustGeneralBalance,
            billedTimeAndFees,
            feeEstimate: estimatedCosts,
            percentageEstimate
        }
    }
}`;

export function fetchBasicMatterDetailsData(
    refreshData: boolean,
    matterId: number,
    onSuccess: (data?: MatterData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
: void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: basicMatterDetailsData,
        variables: {matterId: matterId},
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); });
}

export const basicMatterDetailsData = gql`
query matterDetails ($matterId: Int!){
    matter(id: $matterId){
        id,
        matterGuid,
        fileNumber,
        client,
        title
    }
}`;

export const ArchiveMatterRequestMutation = gql`
mutation archiveMatterRequest( 
    $matterGuid: String, 
    $archive: Boolean,
    $comments: String,
    $taskInput: TaskInput) {
        archiveMatterRequest(
            matterGuid: $matterGuid, 
            archive: $archive,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const BillMatterRequestMutation = gql`
mutation billMatterRequest(
    $matterGuid: String,
    $billFeesAmount: Decimal,
    $billDisbursementsAmount: Decimal,
    $applyTrustAmount: Decimal,
    $writeOffFeesAmount: Decimal,
    $writeOffDisbursementsAmount: Decimal,
    $comments: String,
    $taskInput: TaskInput) {
        billMatterRequest(
            matterGuid: $matterGuid,
            billFeesAmount: $billFeesAmount,
            billDisbursementsAmount: $billDisbursementsAmount,
            applyTrustAmount: $applyTrustAmount,
            writeOffFeesAmount: $writeOffFeesAmount,
            writeOffDisbursementsAmount: $writeOffDisbursementsAmount,    
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const CloseMatterRequestMutation = gql`
mutation closeMatterRequest(
    $matterGuid: String, 
    $close: Boolean,
    $comments: String,
    $taskInput: TaskInput) {
        closeMatterRequest(
            matterGuid: $matterGuid, 
            close: $close,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const TransferMatterTrustToInvoiceRequestMutation = gql`
mutation transferMatterTrustToInvoiceRequest(
    $matterGuid: String,
    $trustTransferAmount: Decimal,
    $invoiceToApply: String,
    $comments: String,
    $taskInput: TaskInput) {
        transferMatterTrustToInvoiceRequest(
            matterGuid: $matterGuid,
            trustTransferAmount: $trustTransferAmount,
            invoiceToApply: $invoiceToApply,  
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const WriteOffMatterDebtorsRequestMutation = gql`
mutation writeOffMatterDebtorsRequest(
    $matterGuid: String, 
    $writeOffDebtorsAmount: Decimal,
    $closeMatterAfterWriteOff: Boolean, 
    $comments: String,
    $taskInput: TaskInput) {
        writeOffMatterDebtorsRequest(
            matterGuid: $matterGuid,
            writeOffDebtorsAmount: $writeOffDebtorsAmount,
            closeMatterAfterWriteOff: $closeMatterAfterWriteOff,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const WriteOffMatterWIPRequestMutation = gql`
mutation writeOffMatterWIPRequest(
    $matterGuid: String, 
    $writeOffFeesAmount: Decimal,
    $writeOffDisbursementsAmount: Decimal, 
    $closeMatterAfterWriteOff: Boolean, 
    $comments: String,
    $taskInput: TaskInput) {
        writeOffMatterWIPRequest(
            matterGuid: $matterGuid, 
            writeOffFeesAmount: $writeOffFeesAmount,
            writeOffDisbursementsAmount: $writeOffDisbursementsAmount, 
            closeMatterAfterWriteOff: $closeMatterAfterWriteOff,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const UpdateArchiveMatterRequestMutation = gql`
mutation updateArchiveMatterRequest( 
    $taskRequestGuid: String, 
    $matterGuid: String, 
    $archive: Boolean,
    $comments: String,
    $taskInput: TaskInput) {
        updateArchiveMatterRequest(
            taskRequestGuid: $taskRequestGuid,
            matterGuid: $matterGuid, 
            archive: $archive,
            comments: $comments
            taskInput: $taskInput
        )
}`;

export const UpdateBillMatterRequestMutation = gql`
mutation updateBillMatterRequest(
    $taskRequestGuid: String, 
    $matterGuid: String,
    $billFeesAmount: Decimal,
    $billDisbursementsAmount: Decimal,
    $applyTrustAmount: Decimal,
    $writeOffFeesAmount: Decimal,
    $writeOffDisbursementsAmount: Decimal,
    $comments: String,
    $taskInput: TaskInput) {
        updateBillMatterRequest(
            taskRequestGuid: $taskRequestGuid,
            matterGuid: $matterGuid,
            billFeesAmount: $billFeesAmount,
            billDisbursementsAmount: $billDisbursementsAmount,
            applyTrustAmount: $applyTrustAmount,
            writeOffFeesAmount: $writeOffFeesAmount,
            writeOffDisbursementsAmount: $writeOffDisbursementsAmount,    
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const UpdateCloseMatterRequestMutation = gql`
mutation updateCloseMatterRequest(
    $taskRequestGuid: String, 
    $matterGuid: String, 
    $close: Boolean,
    $comments: String,
    $taskInput: TaskInput) {
        updateCloseMatterRequest(
            taskRequestGuid: $taskRequestGuid,
            matterGuid: $matterGuid, 
            close: $close,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const UpdateTransferMatterTrustToInvoiceRequestMutation = gql`
mutation updateTransferMatterTrustToInvoiceRequest(
    $taskRequestGuid: String, 
    $matterGuid: String,
    $trustTransferAmount: Decimal,
    $invoiceToApply: String,
    $comments: String,
    $taskInput: TaskInput) {
        updateTransferMatterTrustToInvoiceRequest(
            taskRequestGuid: $taskRequestGuid,
            matterGuid: $matterGuid,
            trustTransferAmount: $trustTransferAmount,
            invoiceToApply: $invoiceToApply,  
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const UpdateWriteOffMatterDebtorsRequestMutation = gql`
mutation updateWriteOffMatterDebtorsRequest(
    $taskRequestGuid: String, 
    $matterGuid: String, 
    $writeOffDebtorsAmount: Decimal,
    $closeMatterAfterWriteOff: Boolean, 
    $comments: String,
    $taskInput: TaskInput) {
        updateWriteOffMatterDebtorsRequest(
            taskRequestGuid: $taskRequestGuid,
            matterGuid: $matterGuid,
            writeOffDebtorsAmount: $writeOffDebtorsAmount,
            closeMatterAfterWriteOff: $closeMatterAfterWriteOff,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const UpdateWriteOffMatterWIPRequestMutation = gql`
mutation updateWriteOffMatterWIPRequest(
    $taskRequestGuid: String, 
    $matterGuid: String, 
    $writeOffFeesAmount: Decimal,
    $writeOffDisbursementsAmount: Decimal, 
    $closeMatterAfterWriteOff: Boolean, 
    $comments: String,
    $taskInput: TaskInput) {
        updateWriteOffMatterWIPRequest(
            taskRequestGuid: $taskRequestGuid,
            matterGuid: $matterGuid, 
            writeOffFeesAmount: $writeOffFeesAmount,
            writeOffDisbursementsAmount: $writeOffDisbursementsAmount, 
            closeMatterAfterWriteOff: $closeMatterAfterWriteOff,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const MatterTaskNotification = gql`
subscription matterTaskNotification {
    matterManagementTaskNotification{
        id,
        status,
        message,
        description
    }
}`;

export const UploadMatterDocumentMutation = gql`
mutation UploadMatterDocumentMutation ($input: MatterDocumentUploadInput!){
    uploadMatterDocument(input: $input){
        status,
        error
    }
}`;

export const UploadMatterDocumentNotification = gql`
subscription uploadMatterDocumentNotification {
    uploadMatterDocumentNotification{
        id,
        status,
        message,
        description
    }
}`;

export interface CurrencySymbolQueryData {
    options: SymbolOptions;
}

export interface SymbolOptions {
    currencySymbol: CurrencySymbol;
}

export interface CurrencySymbol {
    symbol: string;
}

export function fetchCurrencySymbolData(
    refreshData: boolean,
    onSuccess: (data?: CurrencySymbolQueryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: CurrencySymbolQuery,
        variables: {},
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); });
}

export const CurrencySymbolQuery = gql`
    query CurrencySymbolQuery {
        options {
          currencySymbol {
            symbol
          }
        }
    }
`;

export function fetcTenantData(
    refreshData: boolean,
    onSuccess: (data?: TenantData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: TenantDataQuery,
        variables: {},
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); });
}

export const TenantDataQuery = gql`
    query Tenant {
        tenant {
            tenantId
            sourceSystem
            useVerificationLinkForNewUsers
        }
    }
`;

export type MatterForecastSubscriptionData = {
    updateMatterForecastNotification: UpdateMatterForecastNotification;
};

export type UpdateMatterForecastNotification = {
    id:          number;
    message:     string;
    status:      boolean;
    description: null;
};

export const MatterForecastSubscription = gql`
    subscription updateMatterForecast{
		updateMatterForecastNotification{
			id
            message
            status
            description
		}
	}
`;
